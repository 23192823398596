<template>
  <el-config-provider :locale="locale">
    <router-view ></router-view>
  </el-config-provider>
</template>

<script lang="ts">
import { defineComponent,ref } from "vue";
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default defineComponent({
  name: "App",
  components: {},
  setup() {

    return {
      locale: zhCn,
    };
  },
});
</script>

<style lang="scss" >
@import "./styles/public.scss";
html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  // min-width: $screen-min-width;
}
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
}
::-webkit-scrollbar-track {
    background-color: #fff;
}
</style>

import { Module } from "vuex";
import { RootState } from "../types";
import localStorage from "@/public/localStorage";
import sessionStorage from "@/public/sessionStorage";
import request from "@/public/request";
import { ElMessage } from "element-plus";
import { CompanyType } from "@/types/company"
import Mediator from "@/websocket";
/**
 * 权限配置项
 */
export interface PermissionItemValue {
  /**
   * 权限key
   */
  PermissionKey: string;
  /**
   * 配置项key
   */
  ItemKey: string;
  /**
   * 值
   */
  Value: string;
}
/**
 * 权限搜索
 */
export interface PermissionSearch{
  /**
   *  权限key PermissionKey
   */
  permission:string,
  /**
   * 配置项key ItemKey
   */
  item:string
}
/**
 * 身份验证相关state
 */
export interface AuthState {
  /**
   * token
   */
  token: string;
  /**
   * 所属企业资料
   */
  employee: Employee | null;
  /**
   * 当前用户账号
   */
  user: User | null;
  /**
   * 是否有多账号
   */
  multipleAccount:boolean
}
export interface Employee {
  /**
   * Id
   */
  Id: number;
  /**
   * 名称
   */
  RealName: string;
  /**
   * 手机号
   */
  MobilePhone: string;
  /**
   * 邮箱
   */
  Email: string;
  /**
   * 是否为企业管理员
   */
  IsAdmin: boolean;
  /**
   * 公司Id
   */
  CompanyId: number;
  /**
   * 组织Id
   */
  OrgId: number;
  /**
   * 权限配置项
   */
  PermissionItems: PermissionItemValue[];
  /**
   * 公司类型
   */
  CompanyType:CompanyType
}
export interface User {
  Id: number;
  /**
   * 名称
   */
  RealName: string;
  /**
   * 手机号
   */
  MobilePhone: string;
  /**
   * 邮箱
   */
  Email: string | null;
  /**
   * 头像
   */
  Avatar: string | null;
  // qq
  QQ: string | null;
  // 固定电话
  Telephone: string | null;
}

const TOKEN_KEY: string = "jst:token";


const module: Module<AuthState, RootState> = {
  namespaced: true,
  state: {
    token:
      localStorage.getItem(TOKEN_KEY) ??
      sessionStorage.getItem(TOKEN_KEY) ??
      "",
    employee: null,
    user: null,
    multipleAccount:false
  },
  mutations: {
    /**
     *  设置token
     * @param state
     * @param req token string    rememberMe 是否保存到localStorage
     */
    setToken(state, req: { token: string; rememberMe: boolean }) {
      if (!req.token) {
        state.employee = null;
      }
      state.token = req.token;
      localStorage.saveItem(TOKEN_KEY, req.token);
    },
    /**
     * 设置企业账号信息
     * @param state
     * @param emp
     */
    setEmployee(state, emp: Employee) {
      state.employee = emp;
    },
    /**
     * 设置企业账号信息
     * @param state
     * @param user
     */
    setUser(state, user: User) {
      state.user = user;
    },
    /**
     * 设置是否有多账号
     * @param state 
     * @param multipleAccount 
     */
    setMultipleAccount(state,multipleAccount:boolean){
      state.multipleAccount = multipleAccount
    },
    setLogout(state){
      localStorage.saveItem(TOKEN_KEY, "");
      state.user = null
      state.employee = null
      state.multipleAccount = false
      state.token = ""
    }
  },
  actions: {
    logout({ commit }) {
      commit("setToken", "");
      commit("setEmployee", null);
      commit("setUser", null);

      // 清空内容
      localStorage.clear();
      sessionStorage.clear();
    },
    /**
     * 设置token并获得用户/员工信息
     */
    async setTokenAndGetInfo({ commit, dispatch,state }, token: string) {
      commit("setToken", { token: token, rememberMe: true });
      if(!state.token){
        console.error("TOKEN 赋值失败",state.token)
      }
      await dispatch("getCurrentLoggedInfo");
    },
    /**
     * 获得当前员工信息
     * @param param0
     * @returns
     */
    async getCurrentEmployeeInfo({ commit, dispatch, state,rootState }) {
      let employeeRes = await request.get("/api/CurrentEmployee/Current",{},"currentEmployee/current");
      if (!employeeRes) {
        commit("setEmployee", null);
        return null;
      }
      if (employeeRes.Status == false) {
        return;
      }
      commit("setEmployee", employeeRes.Data);
      Mediator.send({
        type: "auth/login",
        content: state.token ?? "",
      });
      await dispatch("menu/getCurrentEmployeeMenuTree", {}, { root: true });
      return employeeRes.Data;
    },
    /**
     * 获得当前用户信息
     */
    async getCurrentUserInfo({ commit }) {
      let userRes = await request.get("/api/CurrentUser/NewCurrent",{},"CurrentUser/NewCurrent");
      if (!userRes) {
        return;
      }
      commit("setUser", userRes);
      return userRes;
    },
    /**
     * 获得当前用户 / 员工的信息
     * @param param0
     */
    async getCurrentLoggedInfo({ dispatch,commit }) {

      await Promise.allSettled([
        dispatch("getCurrentUserInfo"),
        dispatch("getCurrentEmployeeInfo")
      ]);

      // 另起一个请求来确认是否存在多个账号 用来判断是否显示"选择公司"
      request.get("/api/CurrentUser/GetUserBindEmployeeList",{},"CurrentUser/GetUserBindEmployeeList").then(res=>{
        commit("setMultipleAccount",res.length>1)
      })
    },
    /**
     * 检查当前是不是登录了员工
     * 登录了 -> true
     * 未登录 -> 尝试调用接口登录一个默认员工 -> true/false
     * @param param0
     */
    async checkAndTryChooseEmployee({ commit, dispatch, state }) {

      
      // 先尝试拉取员工信息 看看有没有登录中的员工
      if (state.employee) {
        return true
      }else{
        try{
          await dispatch("getCurrentEmployeeInfo")
        }catch{
        }
        if(state.employee){
     
          return true
        }
        // TOdo
      }

      let res = await request.post("/api/AuthUser/TryChooseDefaultEmployee");
      if (res.Status) {
        commit("setToken", { token: res.Data, rememberMe: true });

        await dispatch("getCurrentEmployeeInfo");
        return true;
      }
      return false;
    }
  },
  getters: {
    /**
     * 获得权限
     * @param state 
     * @returns 值
     * 
     * 使用方法 $store.auth.getter.getPermission({key})
     */
    getPermission: function (state) {
      //返回一个函数用于接收
      return function (target: PermissionSearch):string | null {

        if (!state.employee) {
          return null;
        }
  
        let item = state.employee.PermissionItems.find(
          (t) =>
            t.ItemKey == target.item && t.PermissionKey == target.permission
        );
  
        if (item) {
          return item.Value;
        }
        return null;
      };
    },
  },
};

export default module;

import { RouteRecordRaw } from "vue-router";
export default <Array<RouteRecordRaw>>[
  {
    path: "/mainifest/sftTpl/ManifestSFTTpl",
    name: "ManifestSFTTpl",
    component: () =>
      import("@/views/internal/mainifest/sftTpl/ManifestSFTTpl.vue"),
    meta: {
      title: "设收发通",
    },
  },
  {
    path: "/mainifest/sftTpl/manifestSFTTplEdit",
    component: () =>
      import("@/views/internal/mainifest/sftTpl/ManifestSFTTplEdit.vue"),
    name: "ManifestSFTTplEdit",
    meta: { title: "收发通模板编辑" },
  },
  {
    path: "/mainifest/config/ManifestConfigList",
    component: () =>
      import("@/views/internal/mainifest/config/ManifestConfigList.vue"),
    name: "ManifestConfigList",
    meta: { title: "舱单配置" },
  },
  {
    path: "/mainifest/config/ManifestConfigEdit",
    component: () =>
      import("@/views/internal/mainifest/config/ManifestConfigEdit.vue"),
    name: "ManifestConfigEdit",
    meta: { title: "舱单编辑" },
  },
  {
    path: "/mainifest/config/ManifestConfigInfo",
    name: "ManifestConfigInfo",
    component: () =>
      import("@/views/internal/mainifest/config/ManifestConfigInfo.vue"),
    meta: {
      title: "舱单详情",
    },
  },
  {
    path: "/mainifest/create",
    name: "mainifestCreate",
    component: () => import("@/views/internal/mainifest/Create.vue"),
    meta: {
      title: "舱单新建",
    },
  },
  {
    path: "/mainifest/edit",
    name: "mainifestEdit",
    component: () => import("@/views/internal/mainifest/Edit.vue"),
    meta: {
      title: "舱单编辑",
    },
  },

  // 客户

  {
    path: "/mainifest/submitList",
    name: "mainifestSubmitList",
    component: () => import("@/views/internal/mainifest/SubmitList.vue"),
    meta: {
      title: "舱单提交",
    },
  },
  {
    path: "/mainifest/submitInfo",
    name: "mainifestSubmitInfo",
    component: () => import("@/views/internal/mainifest/SubmitInfo.vue"),
    meta: {
      title: "舱单提交详情",
    },
  },
  {
    path: "/mainifest/submitFailedList",
    name: "mainifestSubmitFailedList",
    component: () => import("@/views/internal/mainifest/SubmitFailedList.vue"),
    meta: {
      title: "审核失败",
    },
  },
  {
    path: "/mainifest/remarkConfig",
    name: "remarkConfig",
    component: () => import("@/views/internal/mainifest/RemarkConfig.vue"),
    meta: {
      title: "备注配置",
    },
  },

  // 平台
  {
    path: "/mainifest/verifyList",
    name: "mainifestVerifyList",
    component: () => import("@/views/internal/mainifest/VerifyList.vue"),
    meta: {
      title: "舱单审核",
    },
  },
  {
    path: "/mainifest/verifyInfo",
    name: "mainifestVerifyInfo",
    component: () => import("@/views/internal/mainifest/VerifyInfo.vue"),
    meta: {
      title: "舱单审核详情",
    },
  },
  {
    path: "/mainifest/verifyFailedList",
    name: "mainifestVerifyFailedList",
    component: () => import("@/views/internal/mainifest/VerifyFailedList.vue"),
    meta: {
      title: "舱单提交失败",
    },
  },

  {
    path: "/mainifest/sendList",
    name: "mainifestSendList",
    component: () => import("@/views/internal/mainifest/SendList.vue"),
    meta: {
      title: "舱单发送",
    },
  },
  {
    path: "/mainifest/UploadList",
    name: "mainifestUploadList",
    component: () => import("@/views/internal/mainifest/UploadList.vue"),
    meta: {
      title: "舱单上传",
    },
  },
  {
    path: "/mainifest/sendInfo",
    name: "mainifestSendInfo",
    component: () => import("@/views/internal/mainifest/SendInfo.vue"),
    meta: {
      title: "舱单详情",
    },
  },
  {
    path: "/mainifest/trackList",
    name: "mainifestTrackList",
    component: () => import("@/views/internal/mainifest/TrackList.vue"),
    meta: {
      title: "舱单跟踪",
    },
  },
  {
    path: "/mainifest/trackInfo",
    name: "mainifestTrackInfo",
    component: () => import("@/views/internal/mainifest/TrackInfo.vue"),
    meta: {
      title: "跟踪详情",
    },
  },

  {
    path: "/mainifest/sendFailList",
    name: "mainifestSendFailList",
    component: () => import("@/views/internal/mainifest/SendFailLIst.vue"),
    meta: {
      title: "推送失败",
    },
  },
  {
    path: "/mainifest/sendFailInfo",
    name: "mainifestSendFailInfo",
    component: () => import("@/views/internal/mainifest/SendFailInfo.vue"),
    meta: {
      title: "推送失败详情",
    },
  },
  {
    path: "/mainifest/sendEditInfo",
    name: "mainifestSendEditInfo",
    component: () => import("@/views/internal/mainifest/SendEditInfo.vue"),
    meta: {
      title: "发送失败详情",
    },
  },
  {
    path: "/mainifest/sendEdit",
    name: "mainifestSendEdit",
    component: () => import("@/views/internal/mainifest/SendEdit.vue"),
    meta: {
      title: "发送失败详情",
    },
  }
];

import { RouteRecordRaw } from "vue-router";
export default <Array<RouteRecordRaw>>[
  {
    path:"/",
    name:"mainPage",
    component:()=>import("@/views/external/MainPage.vue"),
    meta:{
      title:"首页",
      allowLogout:true,
      withoutLayout:true
    }
  },
  {
    path:"/workbenchPage",
    name:"workbenchPage",
    component:()=>import("@/views/external/workbenchPage.vue"),
    meta:{
      title:"工作台",
      workpage: true,
      allowLogout:true,
      withoutLayout:true
    }
  },
  {
    path:"/ResourceInfo2",
    name:"ResourceInfo2",
    component:()=>import("@/views/external/workbench/ResourceInfo2.vue"),
    meta:{
      title:"资讯详情",
      workpage: true,
      allowLogout:true,
      withoutLayout:true
    }
  },
  {
    path:"/AssociationProfile",
    name:"ExternalAssociationProfile",
    component:()=>import("@/views/external/AssociationProfile.vue"),
    meta:{
      title:"协会概况",
      workpage: false,
      allowLogout:true,
      withoutLayout:true
    }
  },
  {
    path: "/workbenchLayout",
    name: "WorkbenchLayout",
    component: () => import("@/views/external/WorkbenchLayout.vue"),
    meta:{
      title:"工作台2",
      workpage: true,
      allowLogout:true,
      withoutLayout:true
    },
    children: [
      {
        path: "/workbenchLayout/AssociationSurvey",
        name: "AssociationSurvey",
        meta: {
          title: "协会概况",
          workpage: true,
          allowLogout:true,
          withoutLayout:true
        },
        component: () => import("@/views/external/workbench/AssociationSurvey.vue"),
      },
      {
        path: "/workbenchLayout/EnterpriseAssistance",
        name: "EnterpriseAssistance",
        meta: {
          title: "企业协查",
          workpage: true,
          allowLogout:true,
          withoutLayout:true
        },
        component: () => import("@/views/external/workbench/EnterpriseAssistance.vue"),
      },
      {
        path: "/workbenchLayout/MyConsultation",
        name: "MyConsultation",
        meta: {
          title: "推广资讯",
          workpage: true,
          allowLogout:true,
          withoutLayout:true
        },
        component: () => import("@/views/external/workbench/MyConsultation.vue"),
      },
      {
        path: "/workbenchLayout/PromotionEffect",
        name: "tgxg",
        meta: {
          title: "推广效果",
          workpage: true,
          allowLogout:true,
          withoutLayout:true
        },
        component: () => import("@/views/external/workbench/PromotionEffect.vue"),
      },
      {
        path: "/workbenchLayout/PostMaintenance",
        name: "PostMaintenance",
        meta: {
          title: "岗位维护",
          workpage: true,
          allowLogout:true,
          withoutLayout:true
        },
        component: () => import("@/views/external/workbench/PostMaintenance.vue"),
      },
      {
        path: "/workbenchLayout/DeliveryRecord",
        name: "DeliveryRecord",
        meta: {
          title: "投递记录",
          workpage: true,
          allowLogout:true,
          withoutLayout:true
        },
        component: () => import("@/views/external/workbench/DeliveryRecord.vue"),
      },
      {
        path: "/workbenchLayout/SharedResource",
        name: "SharedResource",
        meta: {
          title: "共享资源",
          workpage: true,
          allowLogout:true,
          withoutLayout:true
        },
        component: () => import("@/views/external/workbench/SharedResource.vue"),
      },
      {
        path: "/workbenchLayout/CompanyInfo",
        name: "CompanyInfo",
        meta: {
          title: "企业详情",
          workpage: true,
          allowLogout:true,
          withoutLayout:true
        },
        component: () => import("@/views/external/workbench/CompanyInfo.vue"),
      },
      {
        path: "/workbenchLayout/MessageInfo",
        name: "MessageInfo",
        meta: {
          title: "资讯详情",
          workpage: true,
          allowLogout:true,
          withoutLayout:true
        },
        component: () => import("@/views/external/workbench/MessageInfo.vue"),
      }
    ],
  },
  {
    path:"/ExternalResources",
    name:"ExternalResources",
    component:()=>import("@/views/external/ExternalResources.vue"),
    meta:{
      title:"外部资讯",
      workpage: false,
      allowLogout:true,
      withoutLayout:true
    }
  },
  {
    path:"/ResourceInfo",
    name:"ResourceInfo",
    component:()=>import("@/views/external/ResourceInfo.vue"),
    meta:{
      title:"资讯详情",
      workpage: false,
      allowLogout:true,
      withoutLayout:true
    }
  },
  {
    path:"/IndustryInformation",
    name:"hyxx",
    component:()=>import("@/views/external/IndustryInformation.vue"),
    meta:{
      title:"行业信息",
      workpage: false,
      allowLogout:true,
      withoutLayout:true
    }
  },
  {
    path:"/PoliciesRegulations",
    name:"zcfg",
    component:()=>import("@/views/external/PoliciesRegulations.vue"),
    meta:{
      title:"政策法规",
      workpage: false,
      allowLogout:true,
      withoutLayout:true
    }
  },
  {
    path:"/SchoolEnterpriseCooperation",
    name:"SchoolEnterpriseCooperation",
    component:()=>import("@/views/external/SchoolEnterpriseCooperation.vue"),
    meta:{
      title:"校企合作",
      workpage: false,
      allowLogout:true,
      withoutLayout:true
    }
  }
];

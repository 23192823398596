import { RouteRecordRaw } from "vue-router";

/*

    用户的个人中心

*/

export default <Array<RouteRecordRaw>>[
  {
    path: "/manage/message",
    name: "manageMessage",
    component: () => import("@/views/external/manage/message/Message.vue"),
    meta: {
      title: "消息管理",
      workpage: true,
      withoutLayout:true
    },
  },
  {
    path: "/manage/messageDetail",
    name: "manageMessageDetail",
    component: () =>
      import("@/views/external/manage/message/MessageDetail.vue"),
    meta: {
      title: "消息详情",
      lastPagePath:"/manage/message",
      workpage: true,
      withoutLayout:true
    },
  },
  {
    path: "/manage/userSetting",
    name: "manageUserSetting",
    component: () => import("@/views/external/manage/UserSetting.vue"),
    meta: {
      title: "个人设置",
      workpage: true,
      withoutLayout:true
    },
  },
];

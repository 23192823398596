import { RouteRecordRaw } from "vue-router";

export default <Array<RouteRecordRaw>>[
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/form/Error.vue"),
    meta: {
      title: "错误提示",
      allowLogout:true
    },
  },
];

import { RouteRecordRaw } from "vue-router";
export default <Array<RouteRecordRaw>>[
  {
    path:"/workbench",
    name:"workbench",
    redirect:"/internal/userCenter"
  },
  {
    path:"/internal/webview",
    name:"internalWebView",
    component:()=>import("@/views/internal/WebView.vue"),
    meta:{
      title:"外部链接"
    }
  },
  {
    path: "/internal/userSetting",
    name: "InternalUserSetting",
    component: () =>
      import("@/views/internal/system/UserSetting.vue"),
    meta: {
      title: "个人设置",
    },
  },
  {
    path: "/internal/userCenter",
    name: "InternalUserCenter",
    component: () =>
      import("@/views/internal/system/UserCenter.vue"),
    meta: {
      title: "个人中心",
    },
  }
];

import { RouteRecordRaw } from "vue-router";
export default <Array<RouteRecordRaw>>[
  {
    path: "/BasicData/ShippingCompany",
    name: "ShippingCompany",
    component: () => import("@/views/internal/BasicData/ShippingCompany.vue"),
    meta: {
      title: "船公司"
    }
  },
  {
    path: "/BasicData/GlobleHaven",
    name: "GlobleHaven",
    component: () => import("@/views/internal/BasicData/GlobleHaven.vue"),
    meta: {
      title: "全球港口"
    }
  },
  {
    path: "/BasicData/Wharf",
    name: "Wharf",
    component: () => import("@/views/internal/BasicData/Wharf.vue"),
    meta: {
      title: "港区码头"
    }
  },
  {
    path: "/BasicData/CostName",
    name: "CostName",
    component: () => import("@/views/internal/BasicData/CostName.vue"),
    meta: {
      title: "费用代码"
    }
  },
  {
    path: "/BasicData/Cur",
    name: "Cur",
    component: () => import("@/views/internal/BasicData/Cur.vue"),
    meta: {
      title: "币种"
    }
  },
  {
    path: "/BasicData/Line",
    name: "Line",
    component: () => import("@/views/internal/BasicData/Line.vue"),
    meta: {
      title: "航线"
    }
  },

];

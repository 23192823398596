import { RouteRecordRaw } from "vue-router";


export default <Array<RouteRecordRaw>>[
    {
        path: "/register",
        name: "userRegister",
        component: () => import("@/views/form/userAccount/Register.vue"),
        meta: {
          title: "申请个人账号",
          allowLogout: true,
        },
      },
      {
        path: "/chooseCompany",
        name: "chooseCompany",
        component: () => import("@/views/form/userAccount/ChooseCompany.vue"),
        meta: {
          title: "选择公司",
        },
      },
      {
        path: "/wxLogin",
        name: "wxLogin",
        component: () => import("@/views/form/userAccount/WxLogin.vue"),
        meta: {
          title: "微信登录",
          allowLogout: true,
        },
      },
      {
        path: "/wxBind",
        name: "wxBind",
        component: () => import("@/views/form/userAccount/WxBind.vue"),
        meta: {
          title: "微信绑定",
          allowLogout: true,
        },
      },
      {
        path: "/manageLogin",
        name: "manageLogin",
        component: () => import("@/views/form/userAccount/ManageLogin.vue"),
        meta: {
          title: "后台管理",
          allowLogout:true
        },
      },
      {
        path: "/JoinAssociation",
        name: "JoinAssociation",
        component: () => import("@/views/form/userAccount/JoinAssociation.vue"),
        meta: {
          title: "申请入会",
          allowLogout:true
        },
      },
      {
        path: "/CompanyInfoEdit",
        name: "CompanyInfoEdit",
        component: () => import("@/views/form/userAccount/CompanyInfoEdit.vue"),
        meta: {
          title: "修改信息",
          allowLogout:true
        },
      },
];
  
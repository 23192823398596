import { RouteRecordRaw } from "vue-router";
export default <Array<RouteRecordRaw>>[
  {
    path: "/external/mainifest/share",
    name: "mainifestShare",
    component: () =>
      import("@/views/external/mainifest/Share.vue"),
    meta: {
      title: "公共舱单服务",
      allowLogout: true,
      withoutLayout:true
    },
  },  {
    path: "/external/mainifest/shareInfo",
    name: "mainifestShareInfo",
    component: () =>
      import("@/views/external/mainifest/ShareInfo.vue"),
    meta: {
      title: "舱单信息",
      allowLogout: true,
      withoutLayout:true
    },
  },
  {
    path: "/external/mainifest/edit",
    name: "mainifestExternalEdit",
    component: () =>
      import("@/views/external/mainifest/Edit.vue"),
    meta: {
      title: "舱单编辑",
      allowLogout: true,
      withoutLayout:true
    },
  },
  {
    path: "/external/mainifest/VGMEdit",
    name: "mainifestExternalVGMEdit",
    component: () =>
      import("@/views/external/mainifest/VGMEdit.vue"),
    meta: {
      title: "VGM编辑",
      allowLogout: true,
      withoutLayout:true
    },
  },
];

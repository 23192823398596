import { RouteRecordRaw } from "vue-router";
export default <Array<RouteRecordRaw>>[
  {
    path:"/DataMaintenance/FreightRateMaintenance/ShipCompany",
    name:"ShipCompany",
    component:()=>import("@/views/internal/DataMaintenance/FreightRateMaintenance/ShipCompany.vue"),
    meta:{
      title:"船司维护"
    }
  },  {
    path:"/DataMaintenance/FreightRateMaintenance/wharfmaintain",
    name:"WharfMaintain",
    component:()=>import("@/views/internal/DataMaintenance/FreightRateMaintenance/WharfMaintain.vue"),
    meta:{
      title:"码头维护"
    }
  },  {
    path:"/DataMaintenance/FreightRateMaintenance/surchargetype",
    name:"SurchargeType",
    component:()=>import("@/views/internal/DataMaintenance/FreightRateMaintenance/SurchargeType.vue"),
    meta:{
      title:"附加费类型"
    }
  },  {
    path:"/DataMaintenance/FreightRateMaintenance/linemaintain",
    name:"LineMaintain",
    component:()=>import("@/views/internal/DataMaintenance/FreightRateMaintenance/LineMaintain.vue"),
    meta:{
      title:"航线维护"
    }
  },{
    path:"/DataMaintenance/FreightRateMaintenance/portmaintain",
    name:"PortMaintain",
    component:()=>import("@/views/internal/DataMaintenance/FreightRateMaintenance/PortMaintain.vue"),
    meta:{
      title:"港口维护"
    }
  },

];

import { RouteRecordRaw } from "vue-router";

// 客户
const customer: Array<RouteRecordRaw> = [
  {
    path: "/system/company/customer/customerList",
    name: "CompanyCustomerList",
    component: () =>
      import("@/views/internal/system/company/customer/CustomerList.vue"),
    meta: {
      title: "客户列表",
    },
  },
  {
    path: "/system/company/customer/customerDetail",
    name: "CompanyCustomerDetail",
    component: () =>
      import("@/views/internal/system/company/customer/CustomerDetail.vue"),
    meta: {
      title: "客户详情",
    },
  },
  {
    path: "/system/company/customer/customerEdit",
    name: "CompanyCustomerEdit",
    component: () =>
      import("@/views/internal/system/company/customer/CustomerEdit.vue"),
    meta: {
      title: "编辑客户信息",
    },
  },
  {
    path: "/system/company/customer/customerRole",
    name: "customerRole",
    component: () => import("@/views/internal/system/company/customer/CustomerRole.vue"),
    meta: {
      title: "公司角色管理",
    },
  },
  {
    path: "/system/company/customer/customerSurvey",
    name: "customerSurvey",
    component: () => import("@/views/internal/system/company/customer/CustomerSurvey.vue"),
    meta: {
      title: "客户概况",
    },
  },
];
// 供应商
const provider: Array<RouteRecordRaw> = [
  {
    path: "/system/company/provider/providerList",
    name: "CompanyProviderList",
    component: () =>
      import("@/views/internal/system/company/provider/ProviderList.vue"),
    meta: {
      title: "供应商列表",
    },
  },
  {
    path: "/system/company/provider/providerDetail",
    name: "CompanyProviderDetail",
    component: () =>
      import("@/views/internal/system/company/provider/ProviderDetail.vue"),
    meta: {
      title: "供应商详情",
    },
  },
  {
    path: "/system/company/provider/providerEdit",
    name: "CompanyProviderEdit",
    component: () =>
      import("@/views/internal/system/company/provider/ProviderEdit.vue"),
    meta: {
      title: "编辑供应商信息",
    },
  },
  {
    path: "/system/company/provider/providerRole",
    name: "providerRole",
    component: () => import("@/views/internal/system/company/provider/ProviderRole.vue"),
    meta: {
      title: "供应商角色管理",
    },
  },
  {
    path: "/system/company/provider/providerSurvey",
    name: "providerSurvey",
    component: () => import("@/views/internal/system/company/provider/ProviderSurvey.vue"),
    meta: {
      title: "供应商概况",
    },
  },
];

// 消息
const message: Array<RouteRecordRaw> = [
  {
    path: "/system/message",
    name: "SystemMessage",
    component: () => import("@/views/internal/system/message/Message.vue"),
    meta: {
      title: "消息管理",
    },
  },
  {
    path: "/system/messageDetail",
    name: "SystemMessageDetail",
    component: () =>
      import("@/views/internal/system/message/MessageDetail.vue"),
    meta: {
      title: "消息详情",
    },
  },
  {
    path: "/system/messageManage",
    name: "SystemMessageManage",
    component: () =>
      import("@/views/internal/system/message/MessageManage.vue"),
    meta: {
      title: "消息推送管理",
    },
  },
  {
    path: "/system/sendGlobalMessage",
    name: "SystemSendGlobalMessage",
    component: () =>
      import("@/views/internal/system/message/SendGlobalMessage.vue"),
    meta: {
      title: "推送消息",
    },
  },
  {
    path: "/system/globalMessageList",
    name: "SystemGlobalMessageList",
    component: () =>
      import("@/views/internal/system/message/GlobalMessageList.vue"),
    meta: {
      title: "推送消息列表",
    },
  }
]


const permission:Array<RouteRecordRaw> = [
  {
    path: "/system/permission/permissionList",
    name: "SystemPermissionList",
    component: () =>
      import("@/views/internal/system/permission/PermissionList.vue"),
    meta: {
      title: "权限管理",
    }
  }
]

//推荐设置
/* 门户管理 */
const recommended:Array<RouteRecordRaw> =[
  {
    path: "/system/HomePageManagement/RecommendedPort",
    name: "RecommendedPort",
    component: () =>
      import("@/views/internal/system/HomePageManagement/RecommendedPort.vue"),
    meta: {
      title: "推荐港口",
    }
  },
  {
    path: "/system/HomePageManagement/RecommendedFreight",
    name: "RecommendedFreight",
    component: () =>
      import("@/views/internal/system/HomePageManagement/RecommendedFreight.vue"),
    meta: {
      title: "推荐运价",
    }
  },
  {
    path: "/system/HomePageManagement/ShipTableMaintain",
    name: "ShipTableMaintain",
    component: () =>
      import("@/views/internal/system/HomePageManagement/ShipTableMaintain.vue"),
    meta: {
      title: "船期表维护",
    }
  },
  {
    path: "/system/HomePageManagement/ShipLetterMaintain",
    name: "ShipLetterMaintain",
    component: () =>
      import("@/views/internal/system/HomePageManagement/ShipLetterMaintain.vue"),
    meta: {
      title: "船司保函维护",
    }
  },
  {
    path:'/system/HomePageManagement/MhShippingDate',
    name:"MhShippingDate",
    component:()=>import('@/views/internal/system/HomePageManagement/MhShippingDate.vue'),
    meta:{
      title:'船期维护'
    }
  },
  {
    path:'/system/HomePageManagement/MhGuarantee',
    name:'MhGuarantee',
    component:()=>import('@/views/internal/system/HomePageManagement/MhGuarantee.vue'),
    meta:{
      title: '保函维护'
    }
  },
  {
    path:'/system/HomePageManagement/AdveManagement',
    name:'AdveManagement',
    component:()=>import('@/views/internal/system/HomePageManagement/AdveManagement.vue'),
    meta:{
      title:'广告管理'
    }
  }
]







const user:Array<RouteRecordRaw> = [
  {
    path: "/system/user/userList",
    name: "SystemUserList",
    component: () => import("@/views/internal/system/user/UserList.vue"),
    meta: {
      title: "个人用户查询",
    },
  },
  {
    path: "/system/user/userSurvey",
    name: "SystemUserSurvey",
    component: () => import("@/views/internal/system/user/UserSurvey.vue"),
    meta: {
      title: "个人用户概况",
    },
  },
]

const association:Array<RouteRecordRaw> = [
  {
    path: "/system/association/AssociationBanner",
    name: "AssociationBanner",
    component: () => import("@/views/internal/system/association/AssociationBanner.vue"),
    meta: {
      title: "协会Banner",
    },
  },
  {
    path: "/system/association/AssociationInformation",
    name: "AssociationInformation",
    component: () => import("@/views/internal/system/association/AssociationInformation.vue"),
    meta: {
      title: "协会新闻",
    },
  },
  {
    path: "/system/association/IndustryInformation",
    name: "IndustryInformation",
    component: () => import("@/views/internal/system/association/IndustryInformation.vue"),
    meta: {
      title: "行业信息",
    },
  },
  {
    path: "/system/association/PoliciesRegulations",
    name: "PoliciesRegulations",
    component: () => import("@/views/internal/system/association/PoliciesRegulations.vue"),
    meta: {
      title: "政策法规",
    },
  },
  {
    path: "/system/association/AssociationOverview",
    name: "AssociationOverview",
    component: () => import("@/views/internal/system/association/AssociationOverview.vue"),
    meta: {
      title: "协会概况",
    },
  },
  {
    path: "/system/association/MembershipReview",
    name: "MembershipReview",
    component: () => import("@/views/internal/system/association/MembershipReview.vue"),
    meta: {
      title: "入会审核",
    },
  },
  {
    path: "/system/association/AssociationAudit",
    name: "AssociationAudit",
    component: () => import("@/views/internal/system/association/AssociationAudit.vue"),
    meta: {
      title: "企业审核",
    },
  },
  {
    path: "/system/association/DataModification",
    name: "DataModification",
    component: () => import("@/views/internal/system/association/DataModification.vue"),
    meta: {
      title: "资料修改审核",
    },
  },
  {
    path: "/system/association/MemberInformation",
    name: "MemberInformation",
    component: () => import("@/views/internal/system/association/MemberInformation.vue"),
    meta: {
      title: "会员资料",
    },
  },
  {
    path: "/system/association/MemberDetail",
    name: "MemberDetail",
    component: () => import("@/views/internal/system/association/MemberDetail.vue"),
    meta: {
      title: "会员详情",
    },
  },
  {
    path: "/system/association/MemberProfile",
    name: "MemberProfile",
    component: () => import("@/views/internal/system/association/MemberProfile.vue"),
    meta: {
      title: "会员概况",
    },
  },
  {
    path: "/system/association/UserManagement",
    name: "UserManagement",
    component: () => import("@/views/internal/system/association/UserManagement.vue"),
    meta: {
      title: "用户管理",
    },
  },
  {
    path: "/system/association/InformationAudit",
    name: "InformationAudit",
    component: () => import("@/views/internal/system/association/InformationAudit.vue"),
    meta: {
      title: "资讯审核",
    },
  },
  {
    path: "/system/association/InformationManagement",
    name: "InformationManagement",
    component: () => import("@/views/internal/system/association/InformationManagement.vue"),
    meta: {
      title: "资讯管理",
    },
  },
  {
    path: "/system/association/ActivityManagement",
    name: "ActivityManagement",
    component: () => import("@/views/internal/system/association/ActivityManagement.vue"),
    meta: {
      title: "活动管理",
    },
  },
  {
    path: "/system/association/RegistrationRecord",
    name: "RegistrationRecord",
    component: () => import("@/views/internal/system/association/RegistrationRecord.vue"),
    meta: {
      title: "报名记录",
    },
  },
  {
    path: "/system/association/SigninRecord",
    name: "SigninRecord",
    component: () => import("@/views/internal/system/association/SigninRecord.vue"),
    meta: {
      title: "签到记录",
    },
  },
  {
    path: "/system/association/TalentManagement",
    name: "TalentManagement",
    component: () => import("@/views/internal/system/association/TalentManagement.vue"),
    meta: {
      title: "高校人才管理",
    },
  },
  {
    path: "/system/association/PromotionEffect",
    name: "PromotionEffect",
    component: () => import("@/views/internal/system/association/PromotionEffect.vue"),
    meta: {
      title: "推广效果",
    },
  }
]





export default <Array<RouteRecordRaw>>[
  {
    path: "/system/menu",
    name: "systemMenu",
    component: () => import("@/views/internal/system/Menu.vue"),
    meta: {
      title: "菜单管理",
    },
  },
  {
    path: "/system/role/roleEdit",
    name: "roleEdit",
    component: () => import("@/views/internal/system/role/RoleEdit.vue"),
    meta: {
      title: "角色设置",
    },
  },
  {
    path: "/system/role/companyRole",
    name: "CompanyRole",
    component: () => import("@/views/internal/system/role/CompanyRole.vue"),
    meta: {
      title: "角色管理",
    },
  },
  {
    path: "/system/employee/list",
    name: "employeeList",
    component: () => import("@/views/internal/system/employee/List.vue"),
    meta: {
      title: "账号设置",
    },
  },
  {
    path: "/system/basicCode",
    name: "basicCode",
    component: () => import("@/views/internal/system/code/BaseCode.vue"),
    meta: {
      title: "基础编码",
    },
  },
  {
    path: "/system/relationMapping",
    name: "relationMapping",
    component: () => import("@/views/internal/system/code/RelationMapping.vue"),
    meta: {
      title: "编码映射",
    },
  },
  {
    path: "/system/CurrentCompanyDetail",
    name: "CurrentCompanyDetail",
    component: () =>
      import("@/views/internal/system/company/CurrentCompanyDetail.vue"),
    meta: {
      title: "后台首页",
    },
  },
  {
    path: "/system/StuffEdit",
    name: "StuffEdit",
    component: () => import("@/views/internal/system/company/StuffEdit.vue"),
    meta: {
      title: "企业员工编辑",
    },
  },
  {
    path: "/system/CompanyApplication",
    name: "CompanyApplication",
    component: () => import("@/views/internal/system/CompanyApplication.vue"),
    meta: {
      title: "入驻申请",
    },
  },
  {
    path: "/system/CompanyAudit",
    name: "CompanyAudit",
    component: () => import("@/views/internal/system/CompanyAudit.vue"),
    meta: {
      title: "企业入驻审核",
    },
  },
  {
    path: "/system/CompanyAuditFinish",
    name: "CompanyAuditFinish",
    component: () => import("@/views/internal/system/CompanyAuditFinish.vue"),
    meta: {
      title: "入驻审核结果",
    },
  },

  {
    path: "/system/company/CreateCompany",
    name: "CreateCompany",
    component: () => import("@/views/internal/system/company/CreateCompany.vue"),
    meta: {
      title: "创建公司",
    },
  },
  
  ...user,
  ...message,
  ...customer,
  ...provider,
  ...permission,
  ...recommended,
  ...association
];

import { RouteRecordRaw } from 'vue-router'
export default <Array<RouteRecordRaw>>[
  {
    path: '/MultiSeaFreight/SiteMaintain',
    name: 'SiteMaintain',
    component: () => import("@/views/internal/MultiSeaFreight/SiteMaintain.vue"),
    meta: {
      title: '站点维护'
    }
  },
  {
    path: '/MultiSeaFreight/CargoMaintain',
    name: 'CargoMaintain',
    component: () => import("@/views/internal/MultiSeaFreight/CargoMaintain.vue"),
    meta: {
      title: '货源地维护'
    }
  },
  {
    path: '/MultiSeaFreight/BoxMaintain',
    name: 'BoxMaintain',
    component: () => import("@/views/internal/MultiSeaFreight/BoxMaintain.vue"),
    meta: {
      title: '站点船司箱况维护'
    }
  },
  {
    path: '/MultiSeaFreight/InlandMaintain',
    name: 'InlandMaintain',
    component: () => import("@/views/internal/MultiSeaFreight/InlandMaintain.vue"),
    meta: {
      title: '内陆段运价维护'
    }
  },
  {
    path: '/MultiSeaFreight/VisitRecord',
    name: 'VisitRecord',
    component: () => import("@/views/internal/MultiSeaFreight/VisitRecord.vue"),
    meta: {
      title: '访问记录'
    }
  },
]
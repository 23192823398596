import { RouteRecordRaw } from "vue-router";

/**
 * 客户等级 相关
 */
const customerLevel: RouteRecordRaw[] = [
  {
    path: "/SeaFreight/FreightManagement/FreightCustomerLevel",
    name: "FreightCustomerLevel",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/FreightCustomerLevel.vue"),
    meta: {
      title: "等级管理"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/FreightCustomerLevelCompany",
    name: "FreightCustomerLevelCompany",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/FreightCustomerLevelCompany.vue"),
    meta: {
      title: "企业等级"
    }
  },
]




export default <Array<RouteRecordRaw>>[
  {
    path: "/SeaFreight/FreightManagement/FCLFreight",
    name: "FCLFreight",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/FCLFreight.vue"),
    meta: {
      title: "整箱运价"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/FCLFreightSearch",
    name: "FCLFreightSearch",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/FCLFreightSearch.vue"),
    meta: {
      title: "运价查询"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/HistoryFreight",
    name: "HistoryFreight",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/components/HistoryFreightDialog.vue"),
    meta: {
      title: "历史运价"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/HistoryFreightSearch",
    name: "HistoryFreightSearch",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/HistoryFreightSearch.vue"),
    meta: {
      title: "历史运价1"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/FCLFreightAdd",
    name: "FCLFreightAdd",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/FCLFreightAdd.vue"),
    meta: {
      title: "新增运价"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/ExpiredFreight",
    name: "ExpiredFreight",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/ExpiredFreight.vue"),
    meta: {
      title: "过期运价"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/FreightDrafts",
    name: "FreightDrafts",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/FreightDrafts.vue"),
    meta: {
      title: "草稿箱"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/FreightModifyList",
    name: "FreightModifyList",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/FreightModifyList.vue"),
    meta: {
      title: "待确认修改"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/extracost",
    name: "ExtraCost",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/ExtraCost.vue"),
    meta: {
      title: "附加费用"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/FreightResult",
    name: "FreightResult",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/FreightResult.vue"),
    meta: {
      title: "批量修改结果"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/SpecialPrice/SpecialPriceRelease",
    name: "SpecialPriceRelease",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/SpecialPrice/SpecialPriceRelease.vue"),
    meta: {
      title: "特价发布"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/SpecialPrice/OnSaleSpecialPrice",
    name: "OnSaleSpecialPrice",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/SpecialPrice/OnSaleSpecialPrice.vue"),
    meta: {
      title: "在售特价管理"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/SpecialPrice/SpecialAccessRecord",
    name: "SpecialAccessRecord",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/SpecialPrice/SpecialAccessRecord.vue"),
    meta: {
      title: "特价访问记录"
    }
  },
  {
    path: "/SeaFreight/FreightManagement/SpecialPrice/RowNumberRecord",
    name: "RowNumberRecord",
    component: () => import("@/views/internal/SeaFreight/FreightManagement/SpecialPrice/RowNumberRecord.vue"),
    meta: {
      title: "排号记录"
    }
  },
  {
    path: "/SeaFreight/NowOrderManagement/ShippingSpaceOrder",
    name: "ShippingSpaceOrder",
    component: () => import("@/views/internal/SeaFreight/NowOrderManagement/ShippingSpaceOrder.vue"),
    meta: {
      title: "现舱详情"
    }
  },
  {
    path: "/SeaFreight/NowOrderManagement/ShippingSpaceList",
    name: "ShippingSpaceList",
    component: () => import("@/views/internal/SeaFreight/NowOrderManagement/ShippingSpaceList.vue"),
    meta: {
      title: "现舱查询"
    }
  },

  {
    path: "/SeaFreight/NowOrderManagement/NowOrderList",
    name: "NowOrderManagementNowOrderList",
    component: () => import("@/views/internal/SeaFreight/NowOrderManagement/NowOrderList.vue"),
    meta: {
      title: "现舱订单"
    }
  },

  {
    path: "/SeaFreight/NowOrderManagement/NowOrderCancelList",
    name: "NowOrderManagementNowOrderCancelList",
    component: () => import("@/views/internal/SeaFreight/NowOrderManagement/NowOrderCancelList.vue"),
    meta: {
      title: "现舱取消订单"
    }
  },


  {
    path: "/SeaFreight/NowOrderManagement/NowOrderUpload",
    name: "NowOrderManagementNowOrderUpload",
    component: () => import("@/views/internal/SeaFreight/NowOrderManagement/NowOrderUpload.vue"),
    meta: {
      title: "确认订舱托单"
    }
  },

  {
    path: "/SeaFreight/NowOrderManagement/provider/MyNowOrderList",
    name: "ProviderMyNowOrderList",
    component: () => import("@/views/internal/SeaFreight/NowOrderManagement/provider/MyNowOrderList.vue"),
    meta: {
      title: "我的现舱"
    }
  },
  {
    path: "/SeaFreight/NowOrderManagement/provider/SaledNowOrderList",
    name: "ProviderSoldNowOrderList",
    component: () => import("@/views/internal/SeaFreight/NowOrderManagement/provider/SoldNowOrderList.vue"),
    meta: {
      title: "已售现舱"
    }
  },
  {
    path: "/SeaFreight/NowOrderManagement/provider/EditNowOrder",
    name: "ProviderEditNowOrder",
    component: () => import("@/views/internal/SeaFreight/NowOrderManagement/provider/EditNowOrder.vue"),
    meta: {
      title: "新建现舱"
    }
  },

  {
    path: "/SeaFreight/NowOrderManagement/provider/SubmitList",
    name: "ProviderNowOrderSubmitList",
    component: () => import("@/views/internal/SeaFreight/NowOrderManagement/provider/SubmitList.vue"),
    meta: {
      title: "提交记录"
    }
  },

  {
    path: "/SeaFreight/NowOrderManagement/provider/DraftList",
    name: "ProviderNowOrderDraftList",
    component: () => import("@/views/internal/SeaFreight/NowOrderManagement/provider/DraftList.vue"),
    meta: {
      title: "现舱草稿"
    }
  },

  ...customerLevel
];

import { Store } from "vuex";
import type { RootState } from "@/store/types";
import { computed, App, Directive, ref } from "vue";
import { Router } from "vue-router"

interface AuthProp {
  path?: string;
  code: string;
}

function install(app: App, store: Store<RootState>, router: Router) {

  let currentPath = "";
  // router
  router.beforeEach(async (to, from, next) => {
    // 获得当前路径
    currentPath = to.path;
    next();
  });

  const instance: Directive = {
    created(el, binging, vnode) {
      let bindingValue = binging.value;
      function setShowStatus(map: Map<string, string[]>) {

        let code = "";
        let path = "";

        if (typeof bindingValue == "string") {
          code = bindingValue;
          path = currentPath;
        } else {
          let p = bindingValue as AuthProp;
          path = p.path ?? currentPath;
          code = p.code;
        }
        const v = map.get(path.toLowerCase()) as any[];

        if (v && v.find((t) => t.Code == code)) {
          if (el.style.display != "") {
            el.style.display = "";
          }
        } else {
          if (el.style.display != "none") {
            el.style.display = "none";
          }
        }
      }





      // 监听还没有得到按钮的时候
      store.subscribe((mutations, state) => {

        if (mutations.type == "menu/setMenuMap") {

          setShowStatus(mutations.payload);

        }
      });
      // 如果得到了按钮
      setShowStatus(store.state.menu.menuMap);


    },
  };
  app.directive("auth-show", instance);
}

export default install;

import {
  createRouter,
  createWebHashHistory,
  Router,
  RouteRecordRaw,
} from "vue-router";

/**
 * 加载路由
 * @param modules
 * @returns
 */
function loadModules(modules: any) {
  let moduleRoutes: Array<RouteRecordRaw> = [];
  for (const path in modules) {
    const module = modules[path];
    const defaultExport = module.default as Array<RouteRecordRaw>;
    moduleRoutes = moduleRoutes.concat(defaultExport);
  }
  return moduleRoutes;
}

// 外部布局 (/external)
const externalModules = import.meta.globEager(
  "./modules/external/**/*.{js,ts}"
);
let externalLayout = loadModules(externalModules);

// 外部布局  工作台头 (工作台) 
const externalWorkbenchModules = import.meta.globEager(
  "./modules/externalWorkbench/workbench/**/*.{js,ts}"
);
let externalWorkbenchLayout = loadModules(externalWorkbenchModules);

/// 外部布局 工作台头 (个人中心)
const externalManageModules = import.meta.globEager(
  "./modules/externalWorkbench/manage/**/*.{js,ts}"
);
let externalManageLayout = loadModules(externalManageModules);



// 表单布局  (/form)
const formModules = import.meta.globEager("./modules/form/**/*.{js,ts}");
let formLayout = loadModules(formModules);

// 内部布局 (/internal)
const internalModules = import.meta.globEager(
  "./modules/internal/**/*.{js,ts}"
);
let internalLayout = loadModules(internalModules);

// external
// externalWorkbench
// form
// internal

// 动态载入modules
let routes: Array<RouteRecordRaw> = [
  {
    // 外部界面(官网)
    path: "/external",
    name: "external",
    component: () => import("@/views/ExternalLayout.vue"),
    children: [
      ...externalLayout,
      // 个人中心
      {
        path: "/external/manageLayout",
        name: "externalManageLayout",
        component: () => import("@/views/external/ManageLayout.vue"),
        children: externalManageLayout
      }
    ],
  },
  // 表单类header
  {
    path: "/formLayout",
    name: "formLayout",
    component: () => import("@/views/FormLayout.vue"),
    meta: {
      withoutLayout: true,
    },
    children: [
      ...formLayout,
      {
        path: "/formLayout/404",
        name: "FormLayoutNotFound",
        component: () => import("@/views/errors/404.vue"),
        meta: {
          title: "页面不存在"
        }
      }
    ],
  },
  // 内部界面
  {
    path: "/internal",
    name: "internal",
    component: () => import("@/views/InternalLayout.vue"),
    children: [
      ...internalLayout,
      {
        path: "/internal/404",
        name: "InternalNotFound",
        component: () => import("@/views/errors/404.vue"),
        meta: {
          title: "页面不存在"
        }
      }
    ],
  },
  //简搜
  {
    path: "/QuickSearch",
    name: "QuickSearch",
    component: () => import("@/views/external/QuickSearch.vue"),
    meta: {
      title: "简搜",
      allowLogout: true
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});
export default router;

import { Module } from "vuex";
import { RootState } from "../types";
import request from "@/public/request";

export const LAYOUTS = {
  // 外部界面
  EXTERNAL: "external",
  // 表单界面
  FORM: "form",
  // 内部界面
  INTERNAL: "internal",
};

/**
 * 身份验证相关state
 */
export interface LayoutState {
  /**
   * 当前处于什么界面
   */
  currentLayout: string;
}
const module: Module<LayoutState, RootState> = {
  namespaced: true,
  state: {
    currentLayout: "",
  },
  getters: {},
  mutations: {
    /** 设置当前layout */
    setCurrentLayout(state, layout: string) {
      state.currentLayout = layout;
    },
  },
  actions: {},
};

export default module;

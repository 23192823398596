import { RouteRecordRaw } from "vue-router";
export default <Array<RouteRecordRaw>>[
  {
    path:"/employeeLogin",
    name:"employeeLogin",
    component:()=>import("@/views/external/Login.vue"),
    meta:{
      title:"企业账号登录",
      allowLogout:true
    }
  }
];

import { RouteRecordRaw } from "vue-router";
export default <Array<RouteRecordRaw>>[
    {
        path: '/internal/cargoManager/trachManager',
        name: 'internal/cargoManager/trachManager',
        component: () => import('@/views/internal/cargoManager/TrachManManager.vue'),
        meta: {
            title: '线上申请'
        }
    },
    {
        path: "/internal/cargoManager/trachManData",
        name: 'internal/cargoManager/trachManData',
        component: () => import('@/views/internal/cargoManager/TrachManData.vue'),
        meta: {
            title: '我的箱单'
        }
    },
    {
        path: "/internal/cargoManager/TrachManMaintain",
        name: 'internal/cargoManager/TrachManMaintain',
        component: () => import('@/views/internal/cargoManager/TrachManMaintain.vue'),
        meta: {
            title: '船司规则'
        }
    },
    {
        path: "/internal/cargoManager/TrachManMaintainHistory",
        name: 'internal/cargoManager/TrachManMaintainHistory',
        component: () => import('@/views/internal/cargoManager/TrachManMaintainHistory.vue'),
        meta: {
            title: '历史记录'
        }
    }
]
<template>
  <el-button @click="back" type="text">
    <i
      data-v-75c99e96
      class="el-icon-arrow-left"
      style="color: rgb(64, 158, 255); cursor: pointer; font-size: 10px; line-height: 17px;"
    ></i>返回
  </el-button>
</template>
    
<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const router = useRouter();
    return {
      back() {
        router.back();
      },
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
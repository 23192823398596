import { Module } from "vuex";
import { RootState } from "../types";
import request from "@/public/request";
import { MenuTree } from "@/types/menu";


/**
 * 菜单相关state
 */
export interface MenuState {
  /**
   * 是否是用外部的菜单
   */
  isExternalWorkbench: boolean;
  /**
   * 是否有特价菜单
   */
  isTeJia:boolean
  // 菜单map
  menuMap: Map<string, any[]>;
}
export interface PathButtonsPayload {
  code: string;
  path: string;
  callback(result: boolean): void;
}

function fillButtonsToMap(map:Map<string,string[]>,item:any){
  map.set(item.Path.toLowerCase(),item.Buttons);
  for(let child of item.Children){
      fillButtonsToMap(map,child);
  }
}



const module: Module<MenuState, RootState> = {
  namespaced: true,
  state: {
    /**
     * 是否是到外部的
     */
    isExternalWorkbench: true,
    isTeJia:false,
    menuMap: new Map<string, any[]>(),
  },
  getters: {
    // 工作台路径
    workbenchPath: (state) => {

      // 如果是平台特殊处理 指向internal
      if (state.isExternalWorkbench) {
        return "/external/workbench";
      } else {
        return "/internal";
      }
    },
    // 用户设置路径
    userSettingPath:(state)=>{
      if (state.isExternalWorkbench) {
        return "/workbench/userSetting";
      } else {
        return "/internal/userSetting";
      }
    }
  },
  mutations: {
    /**
     * 设置是否是用外部的菜单
     * @param state
     * @param value
     */
    setIsExternalWorkbench(state, value) {
      state.isExternalWorkbench = value;
    },
    setMenuMap(state, map) {
      state.menuMap = map;
    },
    setIsTejia(state,value){
      state.isTeJia=value;
    }
  },
  actions: {
    /**
     * 获得当前用户菜单 判断是否是外部菜单
     * @param param0
     */
    async getCurrentEmployeeMenuTree({ commit,dispatch }) {
      let menus = await request.get("/api/Employee/GetCurrentEmployeeMenuTree",{},"Employee/GetCurrentEmployeeMenuTree");
      let externalMenu = menus.find((t) => t.Path == "EXTERNAL_MENU");
      if (externalMenu) {
        let teJiaMenu = externalMenu.Children.find((item) => item.Path == "/workbench/SpecialInfo");
        if(teJiaMenu){
          commit("setIsTejia", true);
        }
        commit("setIsExternalWorkbench", true);
      } else {
        commit("setIsExternalWorkbench", false);
      }
      await dispatch("initMenu",menus)

      
      return menus;

    },

    initMenu({ commit, state }, menuTree: any[]) {
      let map = new Map<string, string[]>();

      for (let item of menuTree) {
        fillButtonsToMap(map, item);
      }
      commit("setMenuMap", map);
    },
    /**
     * 获得这个路径下的按钮 是否是被授权的
     * @param param0 
     * @param payload 
     */
     getPathButtons({state},payload:PathButtonsPayload){
      let v = state.menuMap.get(payload.path.toLocaleLowerCase())
      if(v && v.find(t=>t.Code == payload.code)){
        return true;
      }else{
        return false;
      }

    }
  },
};

export default module;

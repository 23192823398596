import { RouteRecordRaw } from "vue-router";


export default <Array<RouteRecordRaw>>[
    {
        path: "/join/entry",
        name: "JoinEntry",
        component: () => import("@/views/form/join/JoinEntry.vue"),
        meta: {
          title: "入驻申请",
        },
      },
      {
        path: "/Banded",
        name: "Banded",
        component: () => import("@/views/form/Banded.vue"),
        meta: {
          title: "账号已禁用",
        },
      },
    
      {
        path: "/join/createCompany",
        name: "JoinCreateCompany",
        component: () => import("@/views/form/join/CreateCompany.vue"),
        meta: {
          title: "入驻申请",
        },
      },
      {
        path: "/join/bindCompany",
        name: "JoinBindCompany",
        component: () => import("@/views/form/join/BindCompany.vue"),
        meta: {
          title: "关联企业",
        },
      }
];
  